document.addEventListener("DOMContentLoaded", function (event) {

    $('.header-menu-top-list__link.js__trigger').hover(
        function () {
            if(window.innerWidth > 1200) {
                $('.header-bottom').addClass('open')
            }
        },
    );
    $(".wrapper").on("mouseover", function (e) {
        if ($(e.target).closest(".header-bottom, .js__trigger").length === 0 ) {
            if(window.innerWidth > 1200){
                $('.header-bottom').removeClass('open')
            }

        }
    });




    let burgerTrigger = $(".burger");
    let headerMenu = $('.header-menu-top');
    let header = $('.header');

    burgerTrigger.on("click", function () {
        $(this).toggleClass('active');
        headerMenu.toggleClass('active');
        $(".wrapper").addClass('active')
    });
    $(".trigger-close").on("click", function () {
        $(burgerTrigger).trigger("click")
    });


    $(".wrapper").on("click", function (e) {
        if ($(e.target).closest(".header").length === 0 ) {
            if(window.innerWidth < 1200){
                $(".header-menu-top").removeClass("active");
                $(".burger").removeClass("active");
                $(".wrapper").removeClass('active')
            }

        }
    });

    let prevScrollpos = window.pageYOffset;

    window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        prevScrollpos = currentScrollPos;

        if (prevScrollpos > 50) {
            header.addClass('bg');
        } else {
            header.removeClass('bg');
        }
    };


    $('.js__accordion').click(function (e) {
        $(this).toggleClass('active')
        $(this).next().slideToggle()
    });

    $(".sidebar-catalog__btn").click(function () {
        $('.sidebar-catalog').toggleClass('active');
    });

    $('.sidebar-catalog__close').click(function () {
        $(".sidebar-catalog__btn").trigger('click');
    });

    $('.product-accordion__nav').click(function () {
        $(this).toggleClass('active')
        $(this).next().slideToggle()
    });




    // $('.product-slider-main__big').magnificPopup({
    //     delegate: 'a', // child items selector, by clicking on it popup will open
    //     type: 'image'
    //     // other options
    // });


    $('.product-slider-main__big__item').magnificPopup({
        type: 'image',
        mainClass: 'mfp-with-zoom',
        gallery:{
            enabled:true
        },

        zoom: {
            enabled: false,
            duration: 300, // duration of the effect, in milliseconds
            easing: 'ease-in-out', // CSS transition easing function

            opener: function(openerElement) {
                return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
        }

    });

// add active class menu
let url = window.location.pathname.replace('/', '');

$('a.footer-menu__link, a.header-menu-top-list__link').each(function () {
    if($(this).attr("href") == url){
        $(this).addClass('active');
    }
});



});
